import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import QR from "../img/QRcode.png";
import { Container, Row, Col } from 'react-bootstrap';

function Donate() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };

  var str = "generous donors by donating more than";
  // console.log("Test", str.toUpperCase());
  return (
    <>
      <Card>
        <Card.Header>Donate & Help</Card.Header>
        <Card.Body>
          <Card.Title>MAHAPOSHAKARU</Card.Title>
          <Card.Text>
            Honorable {str} Rs.10,00,000/- or more will be termed as
            Mahaposhakaru. And their details will be displayed in this website with photo.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>POSHAKARU</Card.Title>
          <Card.Text>
            Honorable {str} Rs.1,00,000/- or more will be termed as Poshakaru. And their details will be displayed in this website with photo.
          </Card.Text>
          <Button variant="primary">Contact us</Button>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Header>Use the following details to Donate & Help</Card.Header>
        <Card.Body>
          <Card.Title>MahaDaani(Donors)</Card.Title>
          <Card.Text>
            By donating Rs.50,000/- or more will be termed as Maha-Daani. And their details will be displayed in this website with photo.<br />
            By donating Rs.2,000/- or more to become honorary member and by donating Rs.1,000/- or more you you will be termed as Daani.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />

      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>UPI QR CODE</Card.Title>
              <Card.Text>
                 <Card.Img variant="top" src={QR}  style={{ width: '18rem' }}/>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>BANK DETAILS for NEFT</Card.Title>
              <Card.Text>
                Bank name : State Bank of India <br />   
                Beneficiary     :  Keshav Foundation <br />
                A/c No.            : 00000041913406421 <br />
                Branch        : VISVESVARAYA LAYOUT, BANGALORE <br />
                CIF No.        : 91200600128 <br />
                IFS Code     : SBIN0016225 <br />
                MICR Code : 560002204
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>



      <Card>
        <Card.Body>
          <Card.Text>
          <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name :</label>
            <input
              type="name"
              className="form-control"
              placeholder="Enter your full name"
              name="Full name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email ID"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Mobile :</label>
            <input
              type="mobile"
              className="form-control"
              placeholder="Enter mobile number"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Amount:</label>
            <input
              type="amount"
              className="form-control"
              placeholder="Enter amount paid"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Payment details:</label>
            <input
              type="trans"
              className="form-control"
              placeholder="Enter Transaction details /UPI ID"
              name="trans"
              value={formData.trans}
              onChange={handleChange}
            />
          </div>
           <div className="form-group">
            <label>Message:</label>
            <input
              type="message"
              className="form-control"
              placeholder="Write your message"
              name="message "
              value={formData.text}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
          </Card.Text>
        </Card.Body>
      </Card>

    </>
  );
}

export default Donate;
