import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useState } from "react";
import AU from "../img/AboutUs.png";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { BsTypeH6 } from "react-icons/bs";
// import './styles.css'; // Import your CSS file where you define the font

function aboutUs() {
  var str = "generous donors by donating more than";
  // console.log("Test", str.toUpperCase());
  return (
    <>
      <Card>
        <Card.Header>About Us</Card.Header>
        <Card.Body>
          <Card.Title>Idea started in 2013...</Card.Title>
          <Card.Text>
          Understanding the serious requirements of support in matrimony area, where miss guiding and wrong information can lead to major harm to families/ society/ community, we started our services in 2013 in association with various groups of few communities. To start with one dedicated matrimony website for one particular community and by end of next year we started serving for totally 6 communities with dedicated websites. We sponsor & provide 100% FREE premium services to poor aspirant brides and also financial support in marriage.
<br /><br />
Realizing that the communities association, which are doing great social work, also need digital presences, we started provided highly advanced websites absolutely FREE of cost, either in terms of design or maintenance everything absolutely FREE of cost. This enabled community associations to function more effectively and connect with modern people. And we are open extend our services to more and more associations. 
<br /><br />
<h5>Our ambitious work yet to come :</h5> 1. Indentifying & sponsoring the bright students of PUC in rural areas with proper guidance and coaching.<br /> 2. Meditation center for better health of society. <br /> 3. Community center to support poor people.<br /><br />

As we raise by lifting others, we have aquired 80G certificate to benefit our donors in Tax saving.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />
      <br />
 
      <Row>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Our Inspiration</Card.Title>
              <Card.Text>
                <Card.Img variant="top" src={AU} style={{ width: "18rem" }} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Our Founder ...</Card.Title>
              <Card.Text>
               <h6> You raise, <br />
                by lifting others. <br />
                Selfless Giving, <br />
                is the Joy of Living... <br />
                </h6>
                <h6>- Late Shri Keshav Durga Naik</h6>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
          <Card.Body>
            <Card.Title>Public Conferences</Card.Title>
            <Card.Text>
              Starting from 2014 we have been conducting Public Convention along with many socially responsible associations in Karnataka capital Bengaluru. Conducted Conferences for Brides-Grooms almost every year till Corona epidemic and continued the best social practice from Jan 2024by conducting a Bride-Groom Conference in Kumata, Uttara Kannada, Karnatka   
            </Card.Text>
            <Button variant="primary">Gallery</Button>
       </Card.Body>   
       </Card>
 </>
  );
}

export default aboutUs;
